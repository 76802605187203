import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";
import LastThreeRecruitment from "../../components/lastThreeRecruitment";

const Recruitment = ({ data: { lastRecruitment } }) => {
	const lang = "en";

	return (
		<Layout
			seoTitle="Recruitment"
			lang={lang}
			translationPL="/rekrutacja/"
			translationDE="/de/rekrutierung/"
		>
			<SmallBanner title="Recruitment" lang={lang} />

			<section className="recruitment-top">
				<div className="container">
					<div className="row">
						<div className="col-xl-4">
							<h2>Work at PalettenWerk</h2>
						</div>
						<div className="col-xl-8">
							<p>
								Join the leader in the production of pallets in
								Poland. We are a company with an established
								position on the market, where we have been
								operating for over 30 years. We offer stable
								employment on the basis of an employment
								contract. We constantly invest in the
								development of the company and its employees.
								Depending on the position held, we guarantee the
								provision of the necessary equipment, health and
								safety equipment, as well as on-the-job
								training.
							</p>
							<img
								className="img-fluid"
								src={require("../../assets/img/recruitment-image-1.jpg")}
								alt=""
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="recruitment-middle">
				<div className="container">
					<div className="row align-items-end">
						<div className="col-md-4">
							<img
								className="img-fluid"
								src={require("../../assets/img/recruitment-image-2.jpg")}
								alt=""
							/>
						</div>
						<div className="col-md-8">
							<p>
								Our branches are located in three locations:
								Jordanów (Małopolskie Voivodeship) and Sędziszów
								(Świętokrzyskie Voivodeship), where we produce
								wooden pallets, and in Krzeszowice, near Krakow,
								under the brand of HausWerk, where we designand
								manufacture prefabricated wooden houses. Check
								out our current job offers below and join our
								team today!
							</p>
						</div>
					</div>
				</div>
			</section>

			<section className="recruitment-grid">
				<div className="container">
					<h2>We are currently looking for:</h2>
					<LastThreeRecruitment
						lastRecruitment={lastRecruitment}
						lang={lang}
						slug="recruitment"
					/>
				</div>
			</section>
		</Layout>
	);
};

export default Recruitment;

export const query = graphql`
	query {
		lastRecruitment: allWpRecruitment(
			limit: 3
			sort: { fields: date, order: DESC }
			filter: { language: { code: { eq: EN } } }
		) {
			edges {
				node {
					uri
					id
					slug
					title
					excerpt
					language {
						code
						slug
						name
					}
					acfRecruitment {
						recruitmentLocation
						contactInfo
					}
				}
			}
		}
	}
`;
